import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import PageBuilder from '../components/contentTypes/pageBuilder'

const IndexPage = () => {

  const indexQuery = useStaticQuery(
    graphql`query pageQuery {
      allContentfulPage(filter: {slug: {eq: "home"}}) {
        edges {
          node {
            slug
            headerHero {
              ...heroFields
            }
            pageBuilder {
              ... on ContentfulSingleCallout {
                __typename
                ...callOutFields
              }
              ... on ContentfulCalloutHolder {
                __typename
                callouts {
                  ...callOutFields
                }
              }
              ... on ContentfulBeforeAfterGroup {
                __typename
                title
                resultsGroup {
                  entryTitle
                  beforeImage {
                    fluid {
                      src
                    }
                    title
                    description
                  }
                  afterImage {
                    fluid {
                      src
                    }
                  }
                  resultsDetails {
                    resultsDetails
                  }
                  testimonial {
                    ...testimonialFields
                  }
                }
              }
              ... on ContentfulHeroBanner {
                __typename
                ...heroFields
              }
            }
            footerHero {
              ...heroFields
            }
          }
        }
      }
    }
    
    fragment buttonFields on ContentfulButton {
      title
      url
      appointment
      social
    }
    
    fragment testimonialFields on ContentfulTestimonial {
      title
      quote {
        quote
      }
      name
      location
      occupation
    }
    
    fragment heroFields on ContentfulHeroBanner {
      backgroundImage {
        fluid(maxWidth: 2400, maxHeight: 1050, cropFocus: CENTER) {
          src
        }
      }
      title
      subTitle
      heroButton {
        ...buttonFields
      }
    }
    
    fragment callOutFields on ContentfulSingleCallout {
      title
      caseStudies {
        entryTitle
        featuredImage {
          fluid {
            src
          }
        }
        date(formatString: "MMMM DD YYYY")
        content {
          json
        }
      }
      calloutText {
        json
      }
      imageLink
      testimonials {
        ...testimonialFields
      }
      statistics {
        stat
        title
        icon {
          fluid {
            src
          }
        }
        details {
          details
        }
      }
      button {
        ...buttonFields
      }
      backgroundColor
      extraClass
    }`)



  const { headerHero, footerHero, pageBuilder } = indexQuery.allContentfulPage.edges[0].node;

  return (
    <Layout>
      <SEO title="Home" />
      { headerHero && <Hero data={headerHero} /> }
      { <PageBuilder data={pageBuilder} /> }
      <div className="bottomhero">{ footerHero && <Hero data={footerHero} /> }</div>
    </Layout>
  )
}

export default IndexPage